<template>
    <modal class="memberPreMoneyModel" :isShow="isShow">
        <div class="header">押金支付</div>
        <div class="modal-body">
            <div class="left-box">
                <div class="filter-box">
                    <key-board-input class="search" placeholder="押金编码、客户姓名、手机号检索"
                        v-model="varietySelect"
                        :isBlurClose="true" :isKeyEnterClose="true"
                        :keyOptions="{isKeyDownEnter:true}"
                    /> 
                    <div class="query-bnt" @click="loadData()">查询</div>
                </div>
                <div class="data-box">
                    <div class="panel-heading">
                        <div class="td order">序号</div>
                        <div class="td name">客户姓名</div>
                        <div class="td phone">手机号</div>
                        <div class="td deposit">收取押金金额</div>
                        <div class="td money">余额</div>
                        <div class="td number">押金编号</div>
                    </div>
                    <div class="scroll-box">
                        <div class="item-box" :class="{selected:selectData==item}" v-for="(item,index) in pageList" :key="index" @click="currentChange(item)">
                            <div class="tr">
                                <div class="td order">{{(pageIndex-1)*pageSize+index+1}}</div>
                                <div class="td name">{{item.DepositName}}</div>
                                <div class="td phone">{{item.DepositPhone}}</div>
                                <div class="td deposit red">¥{{item.DepositMoney}}</div>
                                <div class="td money red">¥{{Number((item.DepositMoney-item.ConsumeMoney).toFixed(4))}}</div>
                                <div class="td number">{{item.DepositCode}}</div>
                            </div>
                            <div class="more-box">
                                <p>交易时间：{{(new Date(item.CREAT_TIME)).Format("yyyy/MM/dd hh:mm:ss")}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-search-box">
                    <div class="page-box-bottom">
                        <page-turning class="last-page" :pageCount="pageCount" v-model="pageIndex" type="up"><i class=" iconfont icon-fangxiangquan-left"></i></page-turning>
                        <span class="page-number">{{pageIndex}}/{{pageCount}}页</span>
                        <page-turning class="next-page" :pageCount="pageCount" v-model="pageIndex"><i class=" iconfont icon-fangxiangquan-right"></i></page-turning>
                    </div>
                </div>
            </div>

            <div class="right-box" ref="recharRight">
                <div class="title">待支付金额：<span>{{Number(unPaidMoney).toFixed(2)}}</span></div>
                <div class="from-tr">
                    客户姓名： {{selectData?.DepositName}}
                </div>
                <div class="from-tr">
                    手机号码： {{selectData?.DepositPhone}}
                </div>
                <div class="from-tr">
                    押金余额： <span class="money" v-show="selectData">¥{{Number((selectData?.DepositMoney-selectData?.ConsumeMoney).toFixed(4))}}</span>
                </div>
                <div class="from-tr">
                    扣除押金： 
                    <div class="from-input">
                        <input-pattern type="text" pattern="money" v-model="money" ref="money" readonly/>
                    </div>
                </div>
                <div class="keyboard" v-show="false">
                    <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                            data-value="9">9</span></div>
                    <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                            data-value="6">6</span></div>
                    <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                            data-value="3">3</span></div>
                    <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                            data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="confirm()">确认</button>
        </div>
    </modal>
</template>

<script>
import {initKeyBoard} from '/src/common' 
// 押金管理
export default {
    name:'preMoneyModel',
    emits:['close','confirm'],
    props:{
        isShow:{
            type:Boolean,
            default:false
        },
        //需要支付的金额
        rechargeMoney:{
            type:[Number,String],
            default:0
        }
    },
    data(){
        return{
            varietySelect:'',
            pageIndex:1,
            listData:[],
            pageSize:6,
            //选中的数据
            selectData:null,
            money:0,
            userInfo:'',
            //需要支付的金额
            unPaidMoney:0,
        }
    },
    computed:{
        /**条件筛选 */
        listDataFilter(){
            let text=(this.varietySelect||"").trim().toUpperCase();
            if(text){
                return this.listData?.filter(it=>(it.DepositCode||'').toUpperCase().indexOf(text)>=0 
                    || (it.DepositName||'').toUpperCase().indexOf(text)>=0 
                    ||(it.DepositPhone||'').toUpperCase().indexOf(text)>=0);
            }
            return this.listData||[];
        },
        pageList(){
            return this.listDataFilter.slice((this.pageIndex-1)*this.pageSize,this.pageIndex*this.pageSize);
        },
        pageCount(){
            return Math.ceil(this.listDataFilter.length/this.pageSize)||1;
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.emptyData();
            this.loadData();
            this.unPaidMoney=Number(Number(this.rechargeMoney).toFixed(2));
            initKeyBoard.call(this,this.$refs.recharRight,this.$refs.money.$refs.input);
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.emptyData();
                this.loadData();
                this.unPaidMoney=Number(Number(this.rechargeMoney).toFixed(2));
            }
        },
        varietySelect(){
            this.pageIndex=1
        }
    },
    methods:{
        hide(){
            this.$emit('close')
        },
        //加载数据
        loadData(){
            const loading = this.$loading({
                text: "获取押金数据中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            this.$httpAES.post("Bestech.CloudPos.GetDepositsInfo", {
                User_ID:userInfo.User_ID,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    if(d.ResponseBody){
                        this.listData= d.ResponseBody.filter(it=>it.OrderFlag!=1);
                    }
                    this.pageIndex=1;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("押金管理数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        currentChange(item){
            this.selectData=item;
            if(Number(this.selectData.DepositMoney).compute(this.selectData.ConsumeMoney,'-')>this.unPaidMoney){
                this.money = this.unPaidMoney;
            }else{
                this.money = Number(this.selectData.DepositMoney).compute(this.selectData.ConsumeMoney,'-');
            }
            initKeyBoard.call(this,this.$refs.recharRight,this.$refs.money.$refs.input);
        },
        /**点击确认  */
        confirm(){
            if(Number(this.money)<=0){
                this.$message.error('扣除押金金额必须大于0!');
                return
            }
            if(Number(this.money)>Number(this.selectData.DepositMoney).compute(this.selectData.ConsumeMoney,'-')){
                this.$message.error('待支付金额超出押金最大额度');
                return
            }
            if(Number(this.money)!=this.unPaidMoney){
                this.$message.error('待支付金额与扣除押金不一致');
                return
            }
            let param={
                Recharge_Money: Number(this.money), //支付金额
                Deposit_AutoID:this.selectData.Autoid,//押金单ID
            }
            this.$emit("confirm",param);
        },
        /**清空页面数据 */
        emptyData(){
            this.selectData=null;
            this.pageIndex=1;
            this.money="";
        },
    }
}
</script>

<style lang="scss">
@import './memberPreMoneyModel.scss'
</style>